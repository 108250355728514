import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1638996054/carla_if9df4.png",
        "alt": "carla-valdespino"
      }}></img></p>
    <h3>{`Licenciada en Letras Latinoamericanas por la UAEMex. Maestra en Estudios Antropológicos de México por la UDLA-P. Doctorante en Estudios Latinoamericanos por la UNAM. Se ha desempeñado como docente en la Universidad Autónoma del Estado de México, Instituto Tecnológicos de Estudios Superiores de Monterrey, Campus Toluca y Universidad Anáhuac, Sur. En estas instituciones ha impartido clases tanto de literatura como de cultura mexicana y problemas contemporáneos de México. Sus artículos se han publicado en Revista Ciudades, Revista Brújula, La Colmena, entre otros. En 2009 publicó el libro De Noches, dioses y creaciones. En 2021 publicó el libro Toluca-Metepec. Una Heterotopía. Es colaboradora de ViceVersa Noticias. Miembro activo del Ateneo con Sello BKS Toluca, donde fue parte del cuerpo docente del Diplomado Interdisciplinario “Pensamiento Crítico a Juicio”. Oquetza: camino a la raíz es su proyecto personal cuyo objetivo es difundir la cultura prehispánica e indígena de México desde una visión decolonial.`}</h3>
    <hr></hr>
    <h2>{`Viajes`}</h2>
    <h4>{`I`}</h4>
    <p>{`Soy la piel desollada de un cuerpo abandonado en una tarde soleada; el trazo azul arrojado al cesto de la basura; dos metros cuadrados de existencia, un alma sin un punto fijo en el universo. Soy tres punto siete metros de nervios dispuestos a sentir. Soy el árbol olvidado en un viejo patio; la pintura al óleo llena de polvo… el jarrón azul de mamá sobre la repisa.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Soy cuerpo desnudo cual desierto profundo`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`Y desnuda, cual desierto profundo, espero la vida eterna.`}</em></p>
    </blockquote>
    <h4>{`0`}</h4>
    <p>{`Solemne silencio,
oscuridad perpetua, transformada en tinta.
Sombras sobre el cuerpo.
Tormenta-humo que cierra los ojos.
Vida eterna.`}</p>
    <h4>{`II`}</h4>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`de súbito`}</em></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`no he nacido`}</em></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`no he muerto`}</em></p>
    </blockquote>
    <p>{`el centro de la sombra
es la sombra de mi espera
Alejandra Pizarnik`}</p>
    <p>{`Salir de la tormenta, del incendio e implorar por la vida perdida desde una piel derruida. Cerrar los ojos y nacer por segunda vez: la cenestesia de mi alma.`}</p>
    <p>{`¿Vivir?
Sólo dentro de este cuerpo sin alma, en esta piel, en este respiro…`}</p>
    <p>{`Entonces, el alma encuentra su espacio, se esparce por un dibujo sobre mi piel, mi piel que se llena de vida… la memoria de mis ancestros cobra conciencia. Tatuajes que declaran mi ser. Tatuajes que aprisionan mi alma en el cuerpo. Tatuajes como la única forma de estar completa… de ser yo`}</p>
    <hr></hr>
    <h2>{`Lluvia`}</h2>
    <p>{`Los recuerdos se desploman a cuentagotas/viene el torrencial de pensamientos atrapados en el mundo que ha dejado de ser suyo.
Y las memorias, que se deben olvidar, se escurren por las ventanas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      